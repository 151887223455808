<template>
  <form @submit.prevent="saveCustomer">
    <AdminHeader>
      <template v-slot:title>
        <div class="flex items-center">
          <span>
            {{ isNew ? "Nueva suscripción" : customer.company }}
          </span>
          <span
            v-if="!isNew && isLoaded"
            class="ml-4 px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase"
            :class="statusBadgeClasses(customer.plan.status)"
          >
            {{ statusBadgeText(customer.plan.status) }}
          </span>
        </div>
      </template>
      <template v-slot:buttons>
        <a
          v-if="!isNew && isLoaded"
          target="_blank"
          :href="`/subscribe/${customer.id}`"
          class="text-indigo-600 hover:text-indigo-900"
        >
          Link de suscripción
        </a>
      </template>
    </AdminHeader>

    <main class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <TransactionsList v-if="!isNew && isLoaded" :customerId="customer.id" />

      <BaseFormGroup class="mb-12">
        <template v-slot:title> Detalles de la suscripción </template>
        <template v-slot:instructions>
          Se usarán cuando el cliente complete sus datos de pago, para dar de
          alta el cobro recurrente.
        </template>
        <template v-slot:default>
          <div class="col-span-6 sm:col-span-6">
            <BaseInput
              id="company"
              v-model="customer.company"
              label="Razón social"
              placeholder="Acme S.A."
              hint="Nombre legal de la empresa."
              @blur="v$.customer.company.$touch"
              :error="v$.customer.company.$errors[0]?.$message"
            />
          </div>

          <div class="col-span-6 sm:col-span-3">
            <BaseInput
              id="app-name"
              v-model="customer.appName"
              label="Código de aplicación"
              placeholder="nombredeapp"
              hint="Tal como figura en el sistema."
              @blur="
                v$.customer.appName.$touch();
                customer.appName = customer.appName.toLowerCase();
              "
              :error="v$.customer.appName.$errors[0]?.$message"
            />
          </div>

          <div class="col-span-6 sm:col-span-3">
            <BaseSelect
              id="country"
              label="País"
              hint="Ubicación de la empresa."
              v-model="customer.country"
              @blur="v$.customer.country.$touch"
              :options="countries"
              :disabled="isActive"
              :error="v$.customer.country.$errors[0]?.$message"
            />
          </div>

          <div class="col-span-4">
            <BaseInput
              id="plan-name"
              v-model="customer.plan.description"
              label="Descripción del plan"
              placeholder="Abono mensual __ usuarios + __ rastreos"
              hint="Descripción del abono contratado que verá el cliente."
              @blur="v$.customer.plan.description.$touch"
              :error="v$.customer.plan.description.$errors[0]?.$message"
            />
          </div>

          <div class="col-span-6 sm:col-span-2">
            <BaseInput
              id="pausedUntil"
              v-model="customer.plan.pausedUntil"
              label="Fecha de inicio"
              :disabled="isActive"
              placeholder=""
              type="date"
              hint="Fecha de inicio del débito."
            />
          </div>

          <div class="col-span-6 sm:col-span-3">
            <BaseCurrencyInput
              id="amount"
              label="Abono mensual"
              hint="Se debitará una vez por mes, a partir de la fecha de inicio de la suscripción."
              v-model="customer.plan.amount"
              v-model:currency="customer.plan.currency"
              @blur="v$.customer.plan.amount.$touch"
              :error="v$.customer.plan.amount.$errors[0]?.$message"
            />
          </div>

          <div class="col-span-6 sm:col-span-3">
            <BaseCurrencyInput
              id="startupAmount"
              label="Pago inicial"
              hint="Se debitará por única vez al momento de la suscripción. Dejar vacío para bonificar."
              :disabled="isActive"
              :smallHint="!customer.plan.startupAmount ? '' : ''"
              v-model="customer.plan.startupAmount"
              v-model:currency="customer.plan.currency"
              @blur="v$.customer.plan.startupAmount.$touch"
              :error="v$.customer.plan.startupAmount.$errors[0]?.$message"
            />
          </div>

          <div class="col-span-6">
            <BaseTextArea
              id="notes"
              v-model="customer.notes"
              label="Notas internas"
              placeholder="Algunos comentarios adicionales sobre este cliente..."
              hint="No serán visibles por el cliente."
              rows="4"
            />
          </div>
        </template>
        <template v-slot:buttons>
          <BaseButton name="Guardar" type="submit" primary />
        </template>
      </BaseFormGroup>

      <div class="mt-10 sm:mt-0" v-if="!isNew && !isPending">
        <div class="md:grid md:grid-cols-3 md:gap-6">
          <div class="md:col-span-1">
            <div class="px-4 sm:px-0">
              <h3 class="text-lg font-medium leading-6 text-gray-900">
                Datos de pago y contacto
              </h3>
              <p class="mt-1 text-sm leading-5 text-gray-600">
                Estos son los datos completados por el cliente.
              </p>
            </div>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="bg-white shadow overflow-hidden sm:rounded-lg">
              <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl class="sm:divide-y sm:divide-gray-200">
                  <div
                    class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                  >
                    <dt class="text-sm font-medium text-gray-500">Nombre</dt>
                    <dd
                      class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                      {{ customer.contact.name }}
                    </dd>
                  </div>
                  <div
                    class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                  >
                    <dt class="text-sm font-medium text-gray-500">Teléfono</dt>
                    <dd
                      class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                      {{ customer.contact.phone }}
                    </dd>
                  </div>
                  <div
                    class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                  >
                    <dt class="text-sm font-medium text-gray-500">Email</dt>
                    <dd
                      class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                      {{ customer.contact.email }}
                    </dd>
                  </div>
                  <div
                    class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                  >
                    <dt class="text-sm font-medium text-gray-500">Documento</dt>
                    <dd
                      class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                      {{ customer.contact.docType }}
                      {{ customer.contact.docNumber }}
                    </dd>
                  </div>
                  <div
                    class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                  >
                    <dt class="text-sm font-medium text-gray-500">Domicilio</dt>
                    <dd
                      class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                      {{ customer.address.street }}
                      {{ customer.address.streetNumber }},<br />
                      {{ customer.address.city }} ({{
                        customer.address.zipcode
                      }}), {{ customer.address.state }} , {{ customer.country }}
                    </dd>
                  </div>
                  <div
                    class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                  >
                    <dt class="text-sm font-medium text-gray-500">Tarjeta</dt>
                    <dd
                      class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                      {{ customer.card.type }} terminada en
                      {{ customer.card.lastFour }}<br />
                      Expira el {{ customer.card.dueDate }}<br />
                      A nombre de {{ customer.card.holder }}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </form>

  <TransitionRoot as="template" :show="showSaveConfirmationDialog">
    <Dialog
      as="div"
      static
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="showSaveConfirmationDialog = false"
      :open="showSaveConfirmationDialog"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          >
            <div class="sm:flex sm:items-start">

              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <DialogTitle
                  as="h3"
                  class="text-lg leading-6 font-medium text-gray-900"
                >
                  ¿Guardar cambios?
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">Si se modificó el monto del abono mensual, el próximos cobro se hará con el nuevo monto.</p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <!-- <BaseButton name="Guardar" @click="updateCustomer" primary /> -->
              <button
                type="button"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                @click="updateCustomer"
              >
                Guardar
              </button>
              <button
                type="button"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                @click="showSaveConfirmationDialog = false"
                ref="cancelButtonRef"
              >
                Cancelar
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>

  <SaveNotification
    :open="showSavedNotification"
    @close="showSavedNotification = false"
  ></SaveNotification>
</template>

<script>
// import { unref } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, alpha, minValue } from "@/composables/validators";
import SaveNotification from "@/components/SaveNotification.vue";
import NProgress from "nprogress";
import { useCountries } from "@/utils";
import CustomerService from "@/services/CustomerService";
import AdminHeader from "@/components/AdminHeader.vue";
import TransactionsList from "@/components/TransactionsList.vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

export default {
  name: "CustomerEdit",

  components: {
    AdminHeader,
    TransactionsList,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    SaveNotification,
    // ExclamationIcon,
  },

  setup() {
    return {
      v$: useVuelidate(),
      // customer,
      // countries: useCountries(),
    };
  },

  data() {
    return {
      customer: CustomerService.emptyCustomer(),
      countries: useCountries(),
      showSaveConfirmationDialog: false,
      showSavedNotification: false,
    };
  },

  computed: {
    isNew() {
      return this.$route.params.id === "new";
    },
    isActive() {
      return this.customer.plan.status === "active";
    },
    isPending() {
      return this.customer.plan.status === "pending";
    },
    isLoaded(){
      return this.customer.id !== '';
    }
  },

  validations() {
    return {
      customer: {
        company: { required },
        appName: { required, alpha },
        country: { required },
        plan: {
          description: { required },
          amount: {
            required,
            minValue: minValue(1),
          },
          startupAmount: {
            required,
            minValue: minValue(1),
          },
        },
      },
    };
  },

  methods: {
    loadCustomer() {
      NProgress.start();
      CustomerService.loadCustomer(this.$route.params.id).then((result) => {
        this.customer = result;
        this.v$.customer.$model = this.customer;
        NProgress.done();
      });
    },
    saveCustomer() {
      this.v$.$touch();

      if (this.v$.$invalid) return;

      if (!this.isNew) {
        this.showSaveConfirmationDialog = true;
        return;
      }

      this.updateCustomer();
    },
    updateCustomer() {
      NProgress.start();
      CustomerService.saveCustomer(this.customer).then(() => {
        this.$router.push({
          name: "customer-edit",
          params: { id: this.customer.id },
        });
        this.showSaveConfirmationDialog = false;
        this.showSavedNotification = true;
        NProgress.done();
        setTimeout(() => {
          this.showSavedNotification = false;
        }, 3000);
      });
    },
    statusBadgeClasses(status) {
      if (status === "pending") return "bg-yellow-100 text-yellow-800";
      else if (status === "active") return "bg-green-100 text-green-800";
      else if (status === "paused") return "bg-blue-100 text-blue-800";
      //failed?
      else return "bg-red-700 text-white";
    },
    statusBadgeText(status) {
      switch (status) {
        case "active":
          return "activa";
        case "pending":
          return "pendiente";
        case "paused":
          return "pausada";
        case "failed":
          return "fallida";
        default:
          return "???";
      }
    },
  },

  mounted() {
    if (!this.isNew) {
      this.loadCustomer();
    }
    this.v$.customer.$model = this.customer;
    this.v$.$reset();
  },
};
</script>

<style scoped>
pre {
  text-align: left;
}
</style>