<template>
  <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
    <!-- Replace with your content -->

    <!-- <h3 class="text-lg font-medium leading-6 text-gray-900 mx-4 sm:mx-0 lg:-mx-8">
      Historial de transacciones
    </h3> -->
    <div class="px-0 py-6 sm:px-0">
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <h3
            class="text-lg font-medium leading-6 text-gray-900 mx-4 sm:mx-0 mb-2"
          >
            Historial de transacciones
          </h3>
          <div class="py-2 align-middle inline-block min-w-full">
            <div
              class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            >
              <table class="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th
                      class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Fecha
                    </th>

                    <th
                      class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Resultado
                    </th>

                    <th
                      class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Operación
                    </th>

                    <th
                      class="px-6 py-3 bg-gray-50 text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider text-right"
                    >
                      Monto
                    </th>

                    <th class="px-6 py-3 bg-gray-50"></th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr
                    v-for="transaction in transactions"
                    :key="transaction.timestamp"
                  >
                    <td class="px-6 py-4 whitespace-no-wrap">
                      <div class="flex items-center">
                        <div class="flex-shrink-0 h-10 w-10">
                          <div class="h-10 w-10 rounded-full bg-indigo-100 p-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                              />
                            </svg>
                          </div>
                        </div>
                        <div class="ml-4">
                          <div
                            class="text-sm leading-5 font-medium text-gray-900"
                          >
                            {{ formatDate(transaction.timestamp.toDate()) }}
                          </div>
                          <div class="text-sm leading-5 text-gray-500">
                            {{ transaction.code }}
                          </div>
                        </div>
                      </div>
                    </td>

                    <td class="px-6 py-4 whitespace-no-wrap">
                      <div class="text-sm leading-5 text-gray-900">
                        <span
                          class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase"
                          :class="resultBadgeClasses(transaction.result)"
                        >
                          {{ resultBadgeText(transaction.result) }}
                        </span>
                      </div>
                    </td>

                    <td class="px-6 py-4 whitespace-no-wrap">
                      <div class="text-sm leading-5 font-medium text-gray-900">
                        {{ operationText(transaction.operation) }}
                      </div>

                      <div class="text-sm leading-5 text-gray-500">
                        {{ transaction.card }}
                      </div>
                    </td>

                    <td
                      class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500 text-right"
                    >
                      <div class="text-sm leading-5 text-gray-900">
                        $ {{ transaction.amount }}
                      </div>
                      <div class="text-sm leading-5 text-gray-500">
                        {{ transaction.currency }}
                      </div>
                    </td>

                    <td
                      class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium text-gray-300"
                    >
                      <a
                        target="_blank"
                        @click="showTransactionDetails(transaction)"
                        class="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                      >
                        Detalles
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <TransitionRoot as="template" :show="showDetails">
    <Dialog as="div" static class="fixed z-10 inset-0 overflow-y-auto" @close="showDetails = false" :open="showDetails">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6">
            <div>
              <div class="mt-3 text-center sm:mt-5">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                  Detalles
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    <pre class="max-h-80 overflow-scroll text-left">{{details}}</pre>
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <button type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm" @click="showDetails = false">
                Cerrar
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import CustomerService from "@/services/CustomerService";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import NProgress from "nprogress";

export default {
  name: "TransactionsList",
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },
  props: {
    customerId: { type: String },
  },
  data() {
    return {
      details: null,
      showDetails: false,
      transactions: [],
    };
  },
  mounted() {
    // console.log("Transactions list mounted for: " + this.customerId);
    this.updateTransactionList();
  },
  methods: {
    showTransactionDetails(transaction) {
      this.details = transaction;
      this.showDetails = true;
    },
    updateTransactionList() {
      if (this.customerId) {
        NProgress.start();
        // console.log(this.customerId);
        CustomerService.listCustomerTransactions(this.customerId).then(
          (res) => {
            this.transactions = res;
            NProgress.done();
          }
        );
      }
    },
    formatDate(date) {
      return format(date, "PPP", { locale: es });
    },
    resultBadgeClasses(status) {
      if (status === "success") return "bg-green-100 text-green-800";
      else return "bg-red-700 text-white";
    },
    resultBadgeText(status) {
      switch (status) {
        case "success":
          return "exitosa";
        case "fail":
          return "fallida";
        default:
          return "???";
      }
    },
    operationText(status) {
      switch (status) {
        case "charge.startup":
          return "Cobro inicial";
        case "charge.monthly":
          return "Cobro mensual";
        default:
          return "???";
      }
    },
  },
};
</script>

<style>
</style>