<template>
  <div>
    <nav class="bg-gray-800">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <img
                class="h-6"
                src="https://www.persat.com.ar/img/persat_white.png"
                alt="Workflow logo"
              />
            </div>
            <div class="hidden md:block">
              <div class="ml-10 flex items-baseline space-x-4">
                <router-link
                  to="/admin/customers"
                  class="px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700"
                >
                  Suscripciones
                </router-link>
                <button
                  @click="exit"
                  class="px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700"
                >
                  Salir
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <RouterView />

    <!-- <RouterView v-slot="{ Component }">
      <Suspense>
        <component :is="Component" />
      </Suspense>
    </RouterView> -->
  </div>
</template>

<script>
import { RouterView, useRouter } from "vue-router";
import { signOut } from "@/services/Session";

export default {
  components: {
    RouterView,
  },
  setup() {
    const router = useRouter();

    const exit = () => {
      signOut();
      router.push({ name: "login" });
    };

    return {
      exit,
    };
  },
};
</script>

<style>
</style>