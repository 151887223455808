<template>
  <AdminHeader>
    <template v-slot:title> Suscripciones </template>
    <template v-slot:buttons>
      <BaseRouterLinkButton
        name="Nueva suscripción"
        :to="{ name: 'customer-edit', params: { id: 'new' } }"
        :primary="true"
      />
    </template>
  </AdminHeader>

  <main>
    <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <!-- Replace with your content -->
      <div class="px-4 py-6 sm:px-0">
        <div class="flex flex-col">
          <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div
              class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
            >
              <div
                class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
              >
                <table class="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Cliente
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Estado
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Plan
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider text-right"
                      >
                        Abono
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr
                      v-for="customer in customers"
                      :key="customer.id"
                      class="hover:bg-gray-50 cursor-pointer"
                      @click="gotoCustomer(customer.id)"
                    >
                      <td class="px-6 py-4 whitespace-no-wrap">
                        <div class="flex items-center">
                          <div class="flex-shrink-0 h-10 w-10">
                            <div
                              class="h-10 w-10 rounded-full bg-indigo-100 p-2"
                            >
                              <svg
                                class="h-6 w-6 text-indigo-500"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                                />
                              </svg>
                            </div>

                            <!-- <img
                              class="h-10 w-10 rounded-full"
                              src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=4&amp;w=256&amp;h=256&amp;q=60"
                              alt=""
                            /> -->
                          </div>
                          <div class="ml-4">
                            <div
                              class="text-sm leading-5 font-medium text-gray-900"
                            >
                              {{ customer.company }}
                            </div>
                            <div class="text-sm leading-5 text-gray-500">
                              {{ customer.appName }}
                            </div>
                          </div>
                        </div>
                      </td>

                      <td class="px-6 py-4 whitespace-no-wrap">
                        <span
                          class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase"
                          :class="statusBadgeClasses(customer.plan.status)"
                        >
                          {{ statusBadgeText(customer.plan.status) }}
                        </span>
                      </td>

                      <td class="px-6 py-4 whitespace-no-wrap">
                        <div class="text-sm leading-5 text-gray-900">
                          {{ customer.plan.description }}
                        </div>
                        <div class="text-sm leading-5 text-gray-500">
                          {{ country(customer.country)?.flag }}&nbsp;
                          {{ country(customer.country)?.name }}
                        </div>
                      </td>

                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500 text-right"
                      >
                        <div class="text-sm leading-5 text-gray-900">
                          $ {{ customer.plan.amount }}
                        </div>
                        <div class="text-sm leading-5 text-gray-500">
                          {{ customer.plan.currency }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import AdminHeader from "@/components/AdminHeader";
import CustomerService from "@/services/CustomerService";
import { useCountries } from "@/utils";
import NProgress from "nprogress";

export default {
  name: "CustomersList",
  components: {
    AdminHeader,
  },
  data() {
    return {
      customers: [],
    };
  },
  created() {
    NProgress.start();
    console.log(NProgress)
    CustomerService.listCustomers().then((res) => {
      this.customers = res;
      NProgress.done();
    });
  },
  methods: {
    country(code) {
      return useCountries().find((c) => c.code === code);
    },
    gotoCustomer(id) {
      this.$router.push({
        name: "customer-edit",
        params: { id },
      });
    },
    statusBadgeClasses(status) {
      if (status === "pending") return "bg-yellow-100 text-yellow-800";
      else if (status === "active") return "bg-green-100 text-green-800";
      else if (status === "paused") return "bg-blue-100 text-blue-800";
      //failed?
      else return "bg-red-700 text-white";
    },
    statusBadgeText(status) {
      switch (status) {
        case "active":
          return "activa";
        case "pending":
          return "pendiente";
        case "paused":
          return "pausada";
        case "failed":
          return "fallida";
        default:
          return "???";
      }
    },
  },
};
</script>

<style scoped>
</style>